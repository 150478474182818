<template>
  <div
    class="text-center"
    style="position:absolute; top:0; left:0; padding: 20px 10px; width:100%; height:100%; overflow:auto;"
  >
    <div v-if="getVendorDetail !== null">
      <div style="margin-top:10px; height:170px;">
        <v-img
          style="height:170px; margin-bottom:5px;"
          :src="
            getVendorDetail.logo === null || getVendorDetail.logo === ''
              ? `${getProductImageLink}vendors/logo/Vendor-93zz7xnlhut95uysv2x2b9.png`
              : `${getProductImageLink}vendors/logo/${photoFilter(
                  getVendorDetail.logo
                )}`
          "
          aspect-ratio="1.7"
          :contain="true"
        ></v-img>
      </div>
      <div
        style="border-bottom: 1px solid #e0e0e0; margin-top:15px;  text-align:left;"
      >
        <p class="vdr-title-p">Name :</p>
        <p style="font-size:14px;">
          {{ getVendorDetail.name }}
        </p>
      </div>
      <div
        style="border-bottom: 1px solid #e0e0e0; margin-top:15px;  text-align:left;"
      >
        <p class="vdr-title-p">Code :</p>
        <p style="font-size:14px;">
          {{ getVendorDetail.code }}
        </p>
      </div>
      <div
        style="border-bottom: 1px solid #e0e0e0; margin-top:5px; text-align:left;"
      >
        <p class="vdr-title-p">Phone :</p>
        <div style="display:flex; flex:direction; row;">
          <p style="font-size:14px;">
            {{ phone(getVendorDetail) }}
          </p>
        </div>
      </div>
      <div
        style="border-bottom: 1px solid #e0e0e0; margin-top:5px; text-align:left;"
      >
        <p class="vdr-title-p">Email :</p>
        <p style="font-size:14px;">
          {{
            getVendorDetail.email === '' || getVendorDetail.email === undefined
              ? '-'
              : getVendorDetail.email
          }}
        </p>
      </div>
      <div
        style="border-bottom: 1px solid #e0e0e0; margin-top:5px; text-align:left;"
      >
        <p class="vdr-title-p">Address :</p>
        <p style="font-size:14px;">
          {{ getVendorDetail.address !== '' ? getVendorDetail.address : '-' }}
        </p>
      </div>
      <div
        style="border-bottom: 1px solid #e0e0e0; margin-top:5px; text-align:left;"
      >
        <p class="vdr-title-p">Province :</p>
        <p style="font-size:14px;">
          {{
            getVendorDetail.province !== null
              ? getVendorDetail.province.name
              : '-'
          }}
        </p>
      </div>
      <div
        style="border-bottom: 1px solid #e0e0e0; margin-top:5px; text-align:left;"
      >
        <p class="vdr-title-p">City :</p>
        <p style="font-size:14px;">
          {{ getVendorDetail.city !== null ? getVendorDetail.city.name : '-' }}
        </p>
      </div>
      <div
        style="border-bottom: 1px solid #e0e0e0; margin-top:5px; text-align:left;"
      >
        <a href=""></a>
        <p class="vdr-title-p">Website :</p>
        <a
          v-if="getVendorDetail.website !== ''"
          style="font-size:14px;"
          :href="getVendorDetail.website"
          target="_blank"
        >
          {{ getVendorDetail.website }}
        </a>
        <p v-else>-</p>
      </div>
      <div
        style="border-bottom: 1px solid #e0e0e0; margin-top:5px; text-align:left;"
      >
        <p class="vdr-title-p">Classification :</p>
        <p style="font-size:14px;">
          {{
            getVendorDetail.classification !== null
              ? getVendorDetail.classification.name
              : '-'
          }}
        </p>
      </div>
      <div
        style="border-bottom: 1px solid #e0e0e0; margin-top:5px; text-align:left;"
      >
        <p class="vdr-title-p">Sub Classification :</p>
        <p style="font-size:14px;">
          {{
            getVendorDetail.sub_classification !== null
              ? getVendorDetail.sub_classification.name
              : '-'
          }}
        </p>
      </div>
      <div style="margin-top:5px; text-align:left; ">
        <p class="vdr-title-p">About :</p>
        <p style="height:150px; overflow:auto; font-size:14px;">
          {{ getVendorDetail.about !== null ? getVendorDetail.about : '-' }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['getVendorDetail', 'getProductImageLink'])
  },
  methods: {
    photoFilter(link) {
      const lastIndxOf = link.lastIndexOf('/')
      const str = link.substring(lastIndxOf + 1)
      console.log(str)
      if (str === 'null' || str === '') {
        return 'Vendor-93zz7xnlhut95uysv2x2b9.png'
      } else {
        return str
      }
    },
    phone(item) {
      if (item.phone1 === undefined && item.phone2 === undefined) {
        return '-'
      } else if (item.phone1 !== undefined && item.phone2 === undefined) {
        if (item.phone1 === '') {
          return '-'
        } else {
          return item.phone1
        }
      } else if (item.phone1 === undefined && item.phone2 !== undefined) {
        if (item.phone2 === '') {
          return '-'
        } else {
          return item.phone2
        }
      } else {
        if (item.phone1 === '' && item.phone2 === '') {
          return '-'
        } else if (item.phone1 !== '' && item.phone2 === '') {
          return item.phone1
        } else if (item.phone1 === '' && item.phone2 !== '') {
          return item.phone2
        } else {
          return item.phone1 + ' / ' + item.phone2
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.vdr-title-p {
  font-weight: 600;
}
</style>
